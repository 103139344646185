<template>
  <div class="container py-4 py-lg-5" v-cloak>
     <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="text-center">
            <h1 class="fs-4 mb-2">來自日本 木瓜酵素洗顏專家</h1>
            <h2 class="fs-4 mb-5">熱銷{{ anniYear }}年 銷售突破千萬瓶 贏得百萬口碑</h2>
            <img class="img-fluid mb-5 d-none d-lg-block" src="../assets/img/aboutus/aboutus_1.jpg" alt="認識 papawash">
            <img class="img-fluid mb-5 d-lg-none" src="../assets/img/aboutus/aboutus_mb_1.jpg" alt="認識 papawash">
            <p class="mb-5">1985年，Papawash 由日本ESS株式會社創立，ESS(ENZYME SKINCARE SYSTEM)的 E 是 ENZYME(酵素)，S 是 SKINCARE(肌膚保養)，最後的 S 是 SYSTEM(方法)，意指「酵素美容法」，從公司名稱就已說明它對酵素美容法的執念與專注。ESS 以先進科技為基礎，致力鑽研肌膚與木瓜酵素之間的奧秘。特別成立 Papawash 實驗室，投入專業研發多年，創造發揮木瓜酵素作用的 Papawash 潔顏粉，期許透過洗顏美肌的過程，為愛用者帶來無瑕細緻美肌！</p>
            <img class="img-fluid mb-5 d-none d-lg-block" src="../assets/img/aboutus/aboutus_2.jpg" alt="認識 papawash">
            <img class="img-fluid mb-5 d-lg-none" src="../assets/img/aboutus/aboutus_mb_2.jpg" alt="認識 papawash">
            <h3 class="fs-10 mb-1">「{{ anniYear }}年前就已發現的木瓜酵素洗顏奇蹟」</h3>
            <h2 class="fs-5 mb-3">讓肌膚如兒時的細滑 木瓜酵素做到了</h2>
            <p class="mb-5">我們雖面對時間的不可逆，但還是想追求兒時細滑的肌膚觸感。想要擁有細滑美肌，關鍵是要能善用大自然原有的力量，這就是為何 Papawash 堅信自然取材的木瓜酵素是美麗肌膚最好也是最重要的第一步。來自大自然木瓜酵素(Papain)，能溫和洗去累積的老廢角質，深入毛孔去除多餘油脂及髒污，而木瓜酵素最棒的優點在於它能溫和去除老廢角質卻不傷肌膚，且不帶走肌膚原有水分，可天天使用；並排除石油類界面活性劑、礦物油等合成物質，以最自然溫和方式喚醒酵素力量，讓肌膚經由一次一次的洗顏及角質護理，回到健康的美麗，這是 Papawash 一直以來秉持的品牌信念。</p>
            <img class="img-fluid mb-5 d-none d-lg-block" src="../assets/img/aboutus/aboutus_3.jpg" alt="認識 papawash">
            <img class="img-fluid mb-5 d-lg-none" src="../assets/img/aboutus/aboutus_mb_3.jpg" alt="認識 papawash">
            <h3 class="fs-5 mb-2">獨特生酵素洗顏堅持 科技與傳統智慧「粉」相合</h3>
            <p class="mb-5">Papawash 嚴格把關一切成分，木瓜酵素則是挑選農場精心栽培營養價值最高時期的青木瓜，從中萃取木瓜酵素，再運用高科技技術製成細緻粉末，充分保留酵素的新鮮、安定與活性，且「粉」劑型是添加物最少、刺激性最小、也最溫和的產品劑型。當粉狀木瓜酵素加上水，即可喚醒酵素新鮮活性，進而深入毛孔有效的清潔，並去除老廢角質又不傷及肌膚，不帶走肌膚原有水分，每一次使用都是最新鮮。可天天使用，既潔淨又溫和的完美平衡， 讓肌膚重新回到最健康的狀態，擁有透亮絕佳好氣色。</p>
            <img class="img-fluid mb-5 d-none d-lg-block" src="../assets/img/aboutus/aboutus_4.jpg" alt="認識 papawash">
            <img class="img-fluid mb-5 d-lg-none" src="../assets/img/aboutus/aboutus_mb_4.jpg" alt="認識 papawash">
            <h3 class="fs-5 mb-1">聽見台灣愛用者的許願</h3>
            <h3 class="fs-5 mb-3">「能跟日本同步，從木瓜酵素洗顏擁有美肌就太好了」</h3>
            <p class="mb-5">Papawash 以日本原裝方式進入台灣，為的就是讓台灣喜愛木瓜酵素洗顏的您，不必遠赴日本購買或付出額外代購費，輕鬆實惠就能同步擁有日本熱銷{{ anniYear }}年，贏得銷售千萬瓶的Papawash木瓜酵素潔顏粉。立即加入會員，開始擁有自然美肌最重要的第一步。</p>
            <img class="img-fluid mb-5 d-none d-lg-block" src="../assets/img/aboutus/aboutus_5.jpg" alt="認識 papawash">
            <img class="img-fluid mb-5 d-lg-none" src="../assets/img/aboutus/aboutus_mb_5.jpg" alt="認識 papawash">
            <h3 class="fs-5 mb-1">感謝大自然力量 實現肌膚純淨願望</h3>
            <h3 class="fs-5 mb-3">感念地球資源有限，致力友善人類和大自然</h3>
            <p class="mb-5">Papawash 深切感念地球資源的可貴，堅持堅守對人類和大自然都友善的企業理念。所有產品均以國際 FSC 認證紙張包裝，並使用 nonVOC 植物墨印刷，瓶子採用回收材質，希冀未來與地球一起守護所有生物之美，以行動傳達「呵護美肌更要愛護地球」的美好宗旨。</p>
          </div>
        </div>
    </div>
   </div>
</template>

<script>
export default {
  computed: {
    anniYear () {
      const now = new Date()
      const year = now.getFullYear()
      return now.getMonth() + 1 < 10 ? year - 1985 : year - 1984
    }
  }
}
</script>

<style lnag="scss" scoped>
h1, h2, h3{
  font-weight: 600;
}
p{
  line-height: 1.6;
  text-align: justify;
  text-align-last: center;
  -moz-text-align-last: center;
}
</style>
